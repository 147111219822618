import React from 'react';
import i18next from '../config/i18next';
import { getSSOHostname, getSSOClientId } from '../config/ssoUtil';

class Signin extends React.Component {
    render() {
        if (typeof window !== `undefined`) {
            // Use the supplied service parameter if it exists otherwise populate with /modern
            const searchParams = new URLSearchParams(window.location.search);
            const service = searchParams.has('service')
                ? searchParams.get('service')
                : `${window.location.origin}/modern`;

            const ssoHost = getSSOHostname(window.location.origin);
            const clientId = getSSOClientId(window.location.origin);

            // Redirect to SSO Portal create account page
            window.location.assign(
                `https://${ssoHost}/portal/sso/${
                    i18next.language
                }/create-account?clientId=${clientId}&service=${encodeURIComponent(service)}`
            );
        }

        return <noscript>To sign in to your Garmin account, please enable JavaScript in your web browser.</noscript>;
    }
}

export default Signin;
